<template>
  <div>
    <div class="actions-tab in-tab snp-actions">
      <b-dropdown
        variant="link"
        right
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <div class="btn-download">
            <div @click="openIndicatorsModal()">
              <i
                class="icon icon-download"
                style="color: #01ac50; margin-left: 9px"
              ></i>
              <span style="margin-right: 15px">Скачать</span>
            </div>
          </div>
        </template>
      </b-dropdown>
    </div>
    <b-form>
      <div class="card-loader-top">
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group>
              <label>Государственное учреждение:</label>
              <multiselect
                v-model="governmentAgencyVal"
                :options="governmentAgencyArr"
                placeholder="Выберите из списка"
                :allow-empty="false"
                :show-labels="false"
                label="nameRu"
                :disabled="userAccessLevel && userAccessLevel.accessLevel === 3"
                @input="handleGovernmentAgency"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group
              id="form-file-1"
              label="Файл для загрузки:"
              label-for="file"
              description="Выберите файл формата .xls, .xlsx, .doc, .docx, .pdf, .ppt"
            >
              <b-form>
                <b-form-file
                  id="file"
                  v-model="file1"
                  placeholder="Выберите файл"
                  browse-text="Обзор"
                  ref="file-input"
                  @input="inputFile1()"
                >
                </b-form-file>
              </b-form>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group>
              <label
                >Выберите наименование показателя или добавьте новый:</label
              >
              <multiselect
                v-model="indicatorNameVal"
                :options="indicatorNameArr"
                placeholder="Выберите из списка"
                :allow-empty="false"
                :show-labels="false"
                label="name"
                @input="handleIndicatorSelected"
              />
              <b-button
                class="mt-1"
                variant="primary"
                @click="createIndicatorModal('create')"
              >
                Добавить</b-button
              >
              <b-button
                v-if="buttonVisible"
                class="mt-1"
                variant="warning"
                @click="createIndicatorModal('edit')"
              >
                Редактировать</b-button
              >
              <b-button
                v-if="buttonVisible"
                class="mt-1"
                variant="danger"
                :disabled="indicatorNameVal.existFile"
                @click="deleteIndicator()"
              >
                Удалить</b-button
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group>
              <div @click="dateBlock()">
                <label>Дата показателя:</label>
                <b-input-group style="cursor: pointer">
                  <b-form-input
                    v-model="indicatorDate"
                    style="border-radius: none; cursor: pointer"
                    disabled
                  />
                  <b-input-group-append>
                    <button class="date-btn" type="button">
                      <i class="text-right icon icon-keyboard icon-rotate-90" />
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="3" lg="3" v-if="dateBlockVisible">
            <div class="date-form">
              <b-form-group>
                <b-input-group>
                  <label>Год:</label>
                  <multiselect
                    size="5"
                    title="Год"
                    v-model="yearVal"
                    :options="yearArr"
                    placeholder="Выберите из списка"
                    :allow-empty="false"
                    :show-labels="false"
                    label="name"
                    :max-height="230"
                  />
                </b-input-group>
                <b-input-group>
                  <label>Квартал:</label>
                  <multiselect
                    title="Квартал"
                    :searchable="false"
                    :disabled="yearVal === null"
                    :options="querterArr"
                    v-model="quarterVal"
                    placeholder="Выберите из списка"
                    :allow-empty="false"
                    :show-labels="false"
                    label="name"
                    @input="handleQuarter"
                  >
                  </multiselect>
                </b-input-group>
                <b-input-group>
                  <label>Месяц:</label>
                  <multiselect
                    title="Год"
                    v-model="monthVal"
                    :options="monthArr"
                    placeholder="Выберите из списка"
                    :allow-empty="false"
                    :show-labels="false"
                    label="name"
                    :disabled="yearArr && yearVal == null"
                    max-height="130"
                    @input="handleMonthSelect"
                  />
                </b-input-group>
                <b-input-group>
                  <label>День:</label>
                  <multiselect
                    title="Год"
                    v-model="dayVal"
                    :options="dayArr"
                    placeholder="Выберите из списка"
                    :allow-empty="false"
                    :show-labels="false"
                    :disabled="monthInit && monthVal == null"
                    label="name"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="card-loader-bottom">
        <b-button
          variant="success"
          @click="downloadJournal()"
          :disabled="isUploadBtnDisable"
          >Загрузить</b-button
        >
        <b-button variant="default" @click="resetForm('resetForm')">Отмена</b-button>
      </div>
    </b-form>
    <b-tabs class="tabs-in-tabs">
      <b-tab title="Журнал загрузки" style="z-index: auto">
        <div class="table-container">
          <div class="b-table-sticky-header table-responsive-true">
            <table
              class="
                table
                b-table
                table-bordered
                b-table-no-border-collapse
                constructor-table
              "
            >
              <thead>
                <tr>
                  <th>Дата и время загрузки</th>
                  <th>Дата показателя</th>
                  <th>Наименование</th>
                  <th>Сотрудник</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, itemIndex) in this.guFilesInfo">
                  <tr :key="`item-${itemIndex}`">
                    <td>{{ formatDate(new Date(item.createdOn)) }}</td>
                    <td>{{ item.indicatorDate }}</td>
                    <td>{{ item.indicator.name }}</td>
                    <td>{{ item.user }}</td>
                    <td>
                      <span
                        :id="`vertical-dots-${item.id}`"
                        style="height: 20px; padding: 2px; cursor: pointer"
                      >
                        <img
                          :id="`comment-tooltip-trigger-${item.id}`"
                          src="./icons/3-vertical-dots.svg"
                          alt=""
                          style="height: 100%; width: 4px"
                        />
                      </span>
                      <b-tooltip
                        variant="light"
                        :target="`vertical-dots-${item.id}`"
                        triggers="hover"
                      >
                        <b-button
                          variant="light"
                          style="font-size: 12px"
                          @click="deleteFile(item)"
                        >
                          Удалить
                        </b-button>
                        <b-button
                          variant="light"
                          style="font-size: 12px"
                          @click="downloadFile(item)"
                        >
                          Скачать
                        </b-button>
                      </b-tooltip>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <b-modal
      id="name-field"
      v-model="modalVisible"
      size="lg"
      :title="indicatorModelTitle"
      centered
      content-class="indicator-forms"
      body-class="name-field"
      hide-footer
    >
      <template>
        <b-form
          class="modal-indicator"
          id="modalIndicator"
          ref="modalIndicator"
        >
          <b-input v-model="indicatorName" />
          <div class="pt-3" style="text-align: center">
            <b-button
              variant="primary"
              @click="createIndicator()"
              :disabled="indicatorModalType === 'edit'"
              >Добавить</b-button
            >
            <b-button
              variant="warning"
              @click="updateIndicator()"
              :disabled="indicatorModalType === 'create'"
              >Редактировать</b-button
            >
            <b-button variant="light" @click="cancelModal()">Отменить</b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
    <b-modal
      id="constructor-field"
      class="myModalProject"
      v-model="modalIndicatorVisible"
      size="lg"
      title="Список показателей"
      centered
      content-class="modal-forms"
      hide-header
      hide-footer
      body-class="constructor-field"
    >
      <template>
        <div class="accordion" role="tablist">
          <div v-for="(item, inx) in this.allIndicatorArr" :key="inx">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  href="#"
                  v-b-toggle="'accordion-' + inx"
                  variant="info"
                  >{{ item.name }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-' + inx"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <table class="all-indicator-table table b-table">
                    <tbody>
                      <template v-for="(childItem, itemIndex) in item.child">
                        <tr :key="itemIndex">
                          <td>
                            <p
                              @click="downloadFile(childItem)"
                              class="modal-indicator-link"
                            >
                              {{ childItem.file.name }}
                            </p>
                          </td>
                          <td>
                            {{
                              childItem.gu == null ? null : childItem.gu.nameRu
                            }}
                          </td>
                          <td>{{ childItem.indicatorDate }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { LoadingDataSepApi } from "@/modules/report-for-sep/api/LoadingDataSepApi";
import { isEmpty } from "lodash";
import { Ax } from "@/utils";
import { string } from "@amcharts/amcharts4/core";

const MODULE_CODE = "007.003";
const MONTH = [
  {
    id: 1,
    name: "январь",
    quarter: 1,
  },
  {
    id: 2,
    name: "февраль",
    quarter: 1,
  },
  {
    id: 3,
    name: "март",
    quarter: 1,
  },
  {
    id: 4,
    name: "апрель",
    quarter: 2,
  },
  {
    id: 5,
    name: "май",
    quarter: 2,
  },
  {
    id: 6,
    name: "июнь",
    quarter: 2,
  },
  {
    id: 7,
    name: "июль",
    quarter: 3,
  },
  {
    id: 8,
    name: "август",
    quarter: 3,
  },
  {
    id: 9,
    name: "сентябрь",
    quarter: 3,
  },
  {
    id: 10,
    name: "октябрь",
    quarter: 4,
  },
  {
    id: 11,
    name: "ноябрь",
    quarter: 4,
  },
  {
    id: 12,
    name: "декабрь",
    quarter: 4,
  },
];

const QUARTER = [
  {
    id: 1,
    name: "1 квартал",
  },
  {
    id: 2,
    name: "2 квартал",
  },
  {
    id: 3,
    name: "3 квартал",
  },
  {
    id: 4,
    name: "4 квартал",
  },
];

export default {
  name: "loding-data-sep",

  data() {
    return {
      file1: [],
      userId: this.$store.getters.user_uuid,
      userAccessLevel: null,
      modalVisible: false,
      modalIndicatorVisible: false,
      buttonVisible: false,
      dateBlockVisible: false,
      indicatorName: null,
      indicatorDate: null,
      governmentAgencyArr: [],
      governmentAgencyVal: null,
      indicatorNameVal: null,
      indicatorNameArr: [],
      yearVal: null,
      yearArr: [],
      quarterVal: null,
      querterArr: QUARTER,
      monthVal: null,
      monthArr: [],
      dayVal: null,
      dayArr: [],
      formDataVal: null,
      uploadFileInfo: null,
      guFilesInfo: [],
      allIndicatorArr: [],
      indicatorModelTitle: "",
      indicatorModalType: "",
      isUploadBtnDisable: false,
      fields: [
        {
          key: "loadingDateTime",
          label: "Дата и время загрузки",
          sortable: false,
        },
        { key: "indicatorDate", label: "Дата показателя", sortable: false },
        { key: "name", label: "Наименование", sortable: false },
        { key: "employee", label: "Сотрудник", sortable: false },
        { key: "action", label: "", sortable: false },
      ],
      column: [
        {
          key: "name",
          label: "Log File Name",
        },
        {
          key: "fileSize",
          label: "File Size",
        },
        {
          key: "lastModified",
          label: "Last Modified Date",
        },
      ],
    };
  },
  watch: {
    yearVal: function () {
      this.quarterVal = null;
      this.monthVal = null;
      this.dayVal = null;
    },
    indicatorNameVal: function () {
      if (this.indicatorNameVal === null) {
        this.buttonVisible = false;
      }
    },
  },
  mounted() {
    this.getModuleLink();
    this.yearsInit();
    this.monthInit();
  },
  methods: {
    onClick() {
      alert("asdads");
    },
    async getModuleLink() {
      LoadingDataSepApi.get(
        `/api/um/module/link?user=${this.userId}&modulecode=${MODULE_CODE}`
      )
        .then((res) => {
          if (res.status === 200) {
            this.userAccessLevel = res.data;
            this.getGovernmentAgency();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getGovernmentAgency() {
      LoadingDataSepApi.get(`/api/sep/gu?userId=${this.userId}`)
        .then((res) => {
          this.governmentAgencyArr = res.data;
          this.governmentAgencyVal = null;
          if (this.userAccessLevel.accessLevel === 1) {
            this.governmentAgencyVal = res.data[0];
          }
          this.getIndicators(this.governmentAgencyVal?.id);
          this.getFileByGuId();
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getIndicators(governmentAgencyId) {
      const guId = governmentAgencyId === undefined ? "" : governmentAgencyId;
      LoadingDataSepApi.get(`/api/sep/indicators?guId=${guId}`)
        .then((res) => {
          console.log('res', res.data);
          this.indicatorNameArr = res.data.filter((item) => item.active != false);
          this.getFileByGuId();
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    yearsInit() {
      const currentYear = new Date().getFullYear();
      for (let i = 2010; i <= currentYear; i++) {
        this.yearArr.push({ id: i, name: i });
      }
    },
    monthInit() {
      MONTH.forEach((element) => {
        this.monthArr.push(element);
      });
    },
    handleGovernmentAgency() {
      this.getIndicators(this.governmentAgencyVal.id);
      this.indicatorNameVal = null;
      this.buttonVisible = false;
    },
    createIndicator() {
      if (isEmpty(this.indicatorName)) {
        this.makeToast(
          "danger",
          "Вы не ввели наименование показателя",
          "Ошибка!!!"
        );
        return;
      }
      const urlPath = isEmpty(this.governmentAgencyVal)
        ? ``
        : this.governmentAgencyVal?.id;
      LoadingDataSepApi.post(
        `/api/sep/indicator?guId=${urlPath}&name=${this.indicatorName}`,
        {}
      )
        .then((res) => {
          this.indicatorNameArr.push(res.data);
          this.modalVisible = false;
        })
        .catch((err) => console.log("err", err));
    },
    updateIndicator() {
      if (isEmpty(this.indicatorName)) {
        this.makeToast(
          "danger",
          "Вы не ввели наименование показателя",
          "Ошибка!!!"
        );
        return;
      }
      LoadingDataSepApi.put(
        `/api/sep/update/indicator?indicatorId=${this.indicatorNameVal?.id}&name=${this.indicatorName}`,
        {}
      )
        .then((res) => {
          if (res.status === 200) {
            this.getIndicators(this.governmentAgencyVal?.id);
            this.indicatorNameVal = null;
            this.buttonVisible = false;
          }
          this.modalVisible = false;
        })
        .catch((err) => console.log("err", err));
    },
    deleteIndicator() {
      LoadingDataSepApi.delete(
        `/api/sep/indicator?indicatorId=${this.indicatorNameVal.id}`,
        {}
      )
        .then((res) => {
          this.indicatorNameVal = null;
          if (res.status === 200) {
            this.getIndicators(this.governmentAgencyVal?.id);
          }
        })
        .catch((err) => console.log("err", err));
    },
    handleIndicatorSelected() {
      this.buttonVisible = this.indicatorNameVal && this.indicatorNameVal?.id;
    },
    inputFile1() {
      const formData = new FormData();
      if (this.file1 !== null) {
        console.log(this.file1);
        formData.append("file", this.file1);
        this.uploadFile(formData);
      }
    },
    createIndicatorModal(typeButton) {
      if (typeButton === "create") {
        this.indicatorName = "";
        this.indicatorModelTitle = "Введите название показателя";
      } else {
        this.indicatorModelTitle = "Редактировать название показателя";
        this.indicatorName = this.indicatorNameVal?.name;
      }
      setTimeout(() => {
        this.indicatorModalType = typeButton;
        this.modalVisible = true;
      }, 1000);
    },
    cancelModal() {
      this.modalVisible = false;
    },
    handleQuarter() {
      console.log("handleQuarter", this.quarterVal);
      this.monthArr = [];
      const month = MONTH;
      month.forEach((element) => {
        if (element.quarter === this.quarterVal.id)
          console.log("element", element);
        if (element.quarter == this.quarterVal.id) {
          this.monthArr.push(element);
        }
      });
    },
    handleMonthSelect() {
      const days = new Date(this.yearVal.id, this.monthVal.id, 0).getDate();
      for (let d = 1; d <= days; d++) {
        this.dayArr.push({ id: d, name: d });
      }
    },
    async uploadFile(formData) {
      this.isUploadBtnDisable = false;
      try {
        const response = await fetch("/api/file-storage/uploadFile", {
          method: "POST",
          credentials: "include",
          body: formData,
        });
        const result = await response.json();
        if (result.key) {
          this.makeToast("danger", result.key, "Ошибка запроса");
          this.isUploadBtnDisable = true;
          return;
        }
        if (result) {
          this.uploadFileInfo = result;
        } else {
          this.makeToast(
            "danger",
            "Ошибка при загрузке файла",
            "Ошибка запроса"
          );
          return;
        }
      } catch (err) {
        //console.log('err', err);
        this.makeToast("danger", err.toString(), "Ошибка запроса");
      }
    },
    downloadJournal() {
      this.isUploadBtnDisable = true;
      if (this.uploadFileInfo === null) {
        this.makeToast("danger", "Вы не загрузили файл", "Ошибка");
        this.isUploadBtnDisable = false;
        return;
      }
      if (this.indicatorNameVal === null) {
        this.makeToast("danger", "Вы не указали Показатель", "Ошибка");
        this.isUploadBtnDisable = false;
        return;
      }
      if (this.yearVal === null) {
        this.makeToast("danger", "Вы не указали год", "Ошибка");
        this.isUploadBtnDisable = false;
        return;
      }

      const body = {
        indicatorId: this.indicatorNameVal.id,
        fileId: this.uploadFileInfo?.id,
        guId:
          this.governmentAgencyVal == null ? null : this.governmentAgencyVal.id,
        year: this.yearVal.id,
        quarter: this.quarterVal && Number(this.quarterVal.id),
        month: this.monthVal?.name,
        day: this.dayVal?.id,
      };
      try {
        LoadingDataSepApi.post(`/api/sep/download-journal`, body)
          .then((res) => {
            if (res.status === 200) {
              this.makeToast("success", "Элемент сохранен", "Сообщение");
              this.getFileByGuId();
              this.resetForm('uploadForm');              
              this.isUploadBtnDisable = false;
            }
          })
          .catch((err) => console.log("err", err));
      } catch (err) {
        this.makeToast("danger", err.toString(), "Ошибка запроса");
        this.isUploadBtnDisable = false;
      }
    },
    getFileByGuId() {
      const guId =
        this.governmentAgencyVal?.id === undefined
          ? `/api/sep/download-journal/by-guId?guId=`
          : `/api/sep/download-journal/by-guId?guId=${Number(this.governmentAgencyVal?.id)}`;
      LoadingDataSepApi.get(guId)
        .then((res) => {
          if (res.status === 200) {
            this.guFilesInfo = res.data;
            console.log("guFilesInfo", this.guFilesInfo);
          }
        })
        .catch((err) => console.log("err", err));
    },
    getFileIndicatorById() {
      LoadingDataSepApi.get(
        `/api/sep/download-journal/by-indicatorId?indicatorId=${this.indicatorNameVal?.id}`
      )
        .then((res) => console.log("res", res))
        .catch((err) => console.log("err", err));
    },
    resetForm(type) {
      const loadFile = this.uploadFileInfo;
      console.log(loadFile);
      this.file1 = null;
      this.uploadFileInfo = null;
      this.indicatorNameVal = null;
      this.yearVal = null;
      this.quarterVal = null;
      this.monthVal = null;
      this.dayVal = null;
      this.monthArr = MONTH;      
      if (type === 'resetForm' && (loadFile != undefined || loadFile != null)) {
        LoadingDataSepApi.delete(
          `/api/file-storage/deleteFile/${this.uploadFileInfo?.id}`
        )
          .then((res) => {
            console.log("res", res);
          })
          .catch((err) => console.log("err", err));
      }
    },
    openIndicatorsModal() {
      this.allIndicatorArr = [];
      LoadingDataSepApi.get(`/api/sep/download-journal/by-indicatorId`)
        .then((res) => {
          console.log("res", res.data);
          if (res.status === 200) {
            res.data.forEach((item) => {
              //if (!isEmpty(item.data)) {
              const itemInit = {
                active: item.indicator.active,
                guId: item.indicator.guId,
                id: item.indicator.id,
                name: item.indicator.name,
                child: item.data,
              };
              this.allIndicatorArr.push(itemInit);
              //}
            });
            console.log("allIndicatorArr", this.allIndicatorArr);
            this.modalIndicatorVisible = true;
          }
        })
        .catch((err) => console.log("err", err));
    },
    deleteFile(item) {
      LoadingDataSepApi.delete(`/api/sep/download-journal/${item.id}`, {})
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            this.getFileByGuId();
          }
        })
        .catch((err) => console.log("err", err));
    },
    dateBlock() {
      this.dateBlockVisible = !this.dateBlockVisible;
    },
    downloadFile(item) {
      console.log("item", item);
      const url = `/api/file-storage/downloadFile/${item.file.id}`;
      Ax(
        {
          url,
          method: "GET",
          data: null,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        },
        (data) => {
          console.log("data", data);
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${item.file.name}`);
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          console.log("err", error);
        }
      );
    },
    makeToast(variant = null, tostbody, title) {
      this.$bvToast.toast(tostbody, {
        title: title,
        variant: variant,
        autoHideDelay: 4000,
        solid: true,
      });
    },
    formatDate(date) {
      return (
        [
          date.getDate().toString().padStart(2, "0"),
          (date.getMonth() + 1).toString().padStart(2, "0"),
          date.getFullYear(),
        ].join(".") +
        " " +
        [
          date.getHours().toString().padStart(2, "0"),
          date.getMinutes().toString().padStart(2, "0"),
          date.getSeconds().toString().padStart(2, "0"),
        ].join(":")
      );
    },
  },
};
</script>
<style scoped>
.hidden_header {
  display: none;
}

.all-indicator-table {
  font-size: 11px;
  line-height: normal;
}
.modal-indicator-link {
  cursor: pointer;
  color: #1a0dab;
}
.modal .modal-body .name-field {
  background-color: #eef1f8;
  border: 1px solid #bcd2e0;
  border-radius: 6px;
  border-bottom: none;
  max-height: 400px;
  overflow: auto;
}

.modal .modal-body {
  background-color: #eef1f8;
  border: 1px solid #bcd2e0;
  border-radius: 6px;
  border-bottom: none;
  max-height: 400px;
  overflow: auto;
}
.date-btn {
  border: none;
  background: #ffffff;
}
.date-btn i {
  margin: 0;
  top: 0;
  border-color: transparent;
  border-width: 0;
  color: #7c9db3;
  margin-left: -40px;
  font-size: 13px;
}
.date-form .input-group {
  padding-bottom: 3px;
}

ul.nav .nav-tabs {
  z-index: auto;
}
</style>