import axios from 'axios';
import store from '@/services/store';

export abstract class LoadingDataSepApi {
    public code = store.state._instanceCode;
    private static instance = axios.create({
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    });

    static refreshToken(): void {
        this.instance.defaults.headers.Authorization = `Bearer ${store.getters.token}`;
    }

    static async get(url: string, params?: any): Promise<any> {
        this.refreshToken();
        return await this.instance.get(url, { params: params });
    }

    static async post(url: string, body?: any): Promise<any> {
        this.refreshToken();
        return await this.instance.post(url, body || {});
    }

    static async put(url: string, body?: any): Promise<any> {
        this.refreshToken();
        return await this.instance.put(url, body || {});
    }
    static async delete(url: string, body?: any): Promise<any> {
        this.refreshToken();
        return await this.instance.delete(url, body || {});
    }

    static async getAsync(url: string): Promise<any> {
        this.refreshToken();
        const config = {
            'Authorization': `Bearer ${store.getters.token}`
        };

        const response = await axios.get(url, { headers: config })
            .then(res => res.data)
            .catch(err => console.log(err));
        return response;
    }
}