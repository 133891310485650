<template>
  <div class="inner-container">
    <div class="section-title program">
      <span>Социально - экономические показатели</span>
    </div>
    <div class="section-subtitle">
      <i class="icon icon-grid"></i><span>Загрузка для СЭП</span>
    </div>
    <b-tabs class="w-100" v-model="tab">
      <b-tab title="Загрузка данных">
        <template>
          <loading-data-sep></loading-data-sep>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import LoadingDataSep from "@/modules/report-for-sep/LoadingDataSep.vue";


@Component({
  components: {
    "loading-data-sep": LoadingDataSep,
  },
})
export default class ReportForSep extends Vue {
  mounted() {    
    console.log("loading");
  }
}
</script>